.MarkdownView blockquote {
  margin: 0 0 1rem;
  border-left: solid 4px gray;
  padding-left: 0.5rem;
  background-color: #f5f5f5;
}

.MarkdownView {
  margin-bottom: -1rem;
}
