.content-input-item-menu {
  position: absolute;
  display: none;
  z-index: 10;
  /* top: 0;
  right: 10; */
  /* left: -10; */
}

.content-input-item-inner {
  border: 1px solid #c8ccd0;
  border-radius: 4px;
  overflow: hidden;
}

.content-input-item {
  position: relative;
}

/* .path-steps-sidebar-item-menu-hide {
  display: none;
  vertical-align: middle;
} */
    
.content-input-item:hover .content-input-item-menu {
  display: block;
}
