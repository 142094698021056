.number {
    width: 40px;
    text-align: center;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    color: #fff;
    background-color: #fec810;
}
.margin-20px-right {
    margin-right: 20px;
}
.border-radius-4 {
    border-radius: 4px;
}
.margin-80px-bottom {
    margin-bottom: 80px;
}
@media (max-width: 767px){
    .xs-margin-40px-bottom {
        margin-bottom: 40px !important;
    }
}

@media (max-width: 767px){
    .xs-margin-20px-bottom {
        margin-bottom: 20px !important;
    }
}
.padding-40px-left {
    padding-left: 40px;
}

@media (max-width: 767px){
    .xs-text-center {
        text-align: center !important;
    }
}


.features-clean {
    color:#313437;
  }
  
  @media (max-width:767px) {
    .features-clean {
      padding-bottom:10px;
    }
  }
  
  .features-clean p {
    color:#7d8285;
  }
  
  .features-clean h2 {
    font-weight:bold;
    margin-bottom:40px;
    padding-top:40px;
    color:inherit;
  }
  
  @media (max-width:767px) {
    .features-clean h2 {
      margin-bottom:25px;
      padding-top:25px;
      font-size:24px;
    }
  }
  
  .features-clean .intro {
    font-size:16px;
    max-width:500px;
    margin:0 auto 60px;
  }
  
  @media (max-width:767px) {
    .features-clean .intro {
      margin-bottom:40px;
    }
  }
  
  .features-clean .item {
    min-height:100px;
    padding-left:80px;
    margin-bottom:40px;
  }
  
  @media (max-width:767px) {
    .features-clean .item {
      min-height:0;
    }
  }
  
  .features-clean .item .name {
    font-size:20px;
    font-weight:bold;
    margin-top:0;
    margin-bottom:20px;
    color:inherit;
  }
  
  .features-clean .item .description {
    font-size:15px;
    margin-bottom:0;
  }
  
  .features-clean .item .icon {
    font-size:40px;
    color:#fec810;
    float:left;
    margin-left:-65px;
  }
  
  