.eb-sidebar {
    overflow: hidden;
}

.eb-sidebar-expanded {
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;

    width: 250px;
}

.eb-sidebar-collapsed {
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;

    width: 12px;
}

.eb-sidebar-toggle-button {
    position: absolute;
    right: -15px;
    left: auto;
    top: 0px;
    z-index: 1000;
}