.offering-grid-card{
	-webkit-box-shadow: 0 0px 10px rgba(0,0,0,.15);
	box-shadow: 0 0px 10px rgba(0,0,0,.15);
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-ms-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}

.offering-grid-card:hover{
	-webkit-box-shadow: 0 0px 25px rgba(0,0,0, 0.6);;
	box-shadow: 0 0px 25px rgba(0,0,0, 0.6);
    border: #fed136 1px solid;
	
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
	transform: translateY(-10px);
    cursor: pointer;
}
