.profile-card-5{
    margin-top:20px;
}
.profile-card-5 .btn{
    border-radius:2px;
    text-transform:uppercase;
    font-size:12px;
    padding:7px 20px;
    font-weight: bold;
}
.profile-card-5 .card-img-block1 {
    margin: 0 auto;
    position: relative;
    top: -20px;
}

.profile-card-5 h5{
    /* color:#4E5E30; */
    font-weight:600;
}
.profile-card-5 p{
    font-size:14px;
    font-weight:300;
}
