.path-steps-sidebar-item-menu-hide {
  display: none;
  /* height: 50px; */
  vertical-align: middle;
}
    
.path-steps-sidebar-item:hover .path-steps-sidebar-item-menu-hide {
  display: block;
}

.ant-tree-node-selected {
  border: 1px solid rgb(237, 203, 33);
  border-radius: 2px;
  font-weight: bold;
}

.ant-tree-treenode {
  /* border-bottom: 1px solid lightgray; */
  padding:0px !important;
  margin-bottom:2px;
}
