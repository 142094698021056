.btn-grey{
    background-color:#D8D8D8;
	color:#FFF;
}
.rating-block{
	/* background-color:#FAFAFA; */
	border:1px solid #EFEFEF;
	padding:15px 15px 20px 15px;
	border-radius:3px;
}
.bold{
	font-weight:700;
}
.padding-bottom-7{
	padding-bottom:7px;
}

.review-block{
	margin-bottom:15px;
}

.review-block-name{

}

.review-block-date{

}
.review-block-title{
    margin-bottom:1px
}

.review-block-description{

}

.review-block-img {
    width: 75px;
}